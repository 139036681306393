import { Model, Store, Casts } from 'store/Base';
import { observable, computed, action } from 'mobx';
import { Customer } from './Customer';
import { Job, JobStore } from './Job';
import { Activity } from './Activity';
import { TripStore } from './Trip';
import { DocumentStore } from './Document';
import { TaskStore } from './Task';
import OrderStatus from './enums/OrderStatus';
import { uniqBy, sumBy } from 'lodash';
import { User } from './User';
import { InvoiceLineStore } from './InvoiceLine';
import { formatMoneyEuro, formatMoneyPound, now } from 'helpers';
import OrderSubcontractStatus from './enums/OrderSubcontractStatus';
import { OrderSubcontractStore } from './OrderSubcontract';
import { showErrorNotifications, showSaveNotification } from 'helpers/notification';
import { ContactStore } from './Contact';
import { TYPE_CUSTOMS, TYPE_UPDATE } from 'store/enums/ContactTypes';
import { OrderSurchargeStore } from './OrderSurcharge';
import { SURCHARGE_TYPE_PERCENTAGE } from './enums/SurchargeTypes';
import { SURCHARGE_CODE_BASE_PRICE } from './Surcharge/Surcharge';
import { omit } from 'lodash';
import { DALLESITRANSPORTNL } from 'store/enums/OrderAssignEntity';


export const UNIT_FTL = 'ftl';
export const UNIT_LTL = 'ltl';
export const UNIT_OTHER = 'other';
export const UNITS = [UNIT_FTL, UNIT_LTL, UNIT_OTHER];

export const CURRENCY_EURO = 'euro';
export const CURRENCY_POUND = 'pound';
export const CURRENCIES = [CURRENCY_EURO, CURRENCY_POUND];

export const PACKAGING_TYPE_BIG_BAGS = 'big bags';
export const PACKAGING_TYPE_BALES = 'bales';
export const PACKAGING_TYPE_BILLETS = 'billets';
export const PACKAGING_TYPE_BUNDLES = 'bundles';
export const PACKAGING_TYPE_COLLI = 'colli';
export const PACKAGING_TYPE_COILS = 'coils';
export const PACKAGING_TYPE_CARTONS = 'cartons';
export const PACKAGING_TYPE_DRUMS = 'drums';
export const PACKAGING_TYPE_EUROPALLETS = 'europallets';
export const PACKAGING_TYPE_PALLETS = 'pallets';
export const PACKAGING_TYPE_REELS = 'reels';
export const PACKAGING_TYPE_SLABS = 'slabs';
export const PACKAGING_TYPE_LOTS = 'lots';
export const PACKAGING_TYPE_LDM = 'ldm';
export const PACKAGING_TYPE_FTL = 'ftl';
export const PACKAGING_TYPES = [PACKAGING_TYPE_FTL, PACKAGING_TYPE_BIG_BAGS, PACKAGING_TYPE_BALES, PACKAGING_TYPE_BILLETS, PACKAGING_TYPE_BUNDLES, PACKAGING_TYPE_COLLI, PACKAGING_TYPE_COILS, PACKAGING_TYPE_CARTONS, PACKAGING_TYPE_DRUMS, PACKAGING_TYPE_EUROPALLETS, PACKAGING_TYPE_PALLETS, PACKAGING_TYPE_REELS, PACKAGING_TYPE_SLABS, PACKAGING_TYPE_LOTS, PACKAGING_TYPE_LDM];

export const CURRENCY_FLAG = {
    euro: 'eu',
    pound: 'gb',
};

export class Order extends Model {
    static backendResourceName = 'order';
    static omitFields = ['confidence'];

    @observable id = null;
    @observable orderNumber = '';
    @observable status = OrderStatus.NEW;
    @observable unit = UNIT_FTL;
    @observable invoiceReference = '';
    @observable salesPrice = '';
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable remarks = '';
    @observable packagingAmount = '';
    @observable packagingType = null;
    @observable weight = null;
    @observable currency = CURRENCY_EURO;

    // [TODO] change name to adr
    @observable unNumber = false;

    @observable fuelSurcharge = false;
    @observable goodsDescription = '';
    @observable subcontracted = false;
    @observable hasProofOfDelivery = false;
    @observable firstTrailerNumber = '';
    @observable hasTasks = false;
    @observable notes = '';
    @observable assignedEntity = DALLESITRANSPORTNL;

    @observable secretHash;
    @observable confidence = {};

    relations() {
        return {
            customer: Customer,
            assignedUser: User,
            createdBy: User,
            jobs: JobStore,
            trips: TripStore,
            firstJob: Job,
            lastJob: Job,
            firstActivity: Activity,
            lastActivity: Activity,
            documents: DocumentStore,
            invoicedIn: InvoiceLineStore,
            subcontracts: OrderSubcontractStore,
            orderContactUpdate: ContactStore,
            orderContactCustoms: ContactStore,
            tasks: TaskStore,
            orderSurcharges: OrderSurchargeStore,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    setInput(name, value) {
        if (name === 'customer') {
            delete this.confidence['customer'];
        }
        super.setInput(name, value);
    }

    @computed get tagLabel() {
        return `O${this.orderNumber}`;
    }

    @computed get editUrl() {
        return `/orders/${this.id}/edit`;
    }

    @computed get isPlanned() {
        return [
            OrderStatus.PLANNED,
            OrderStatus.IN_PROGRESS,
            OrderStatus.COMPLETED,
            OrderStatus.CANCELED
        ].includes(this.status);
    }

    @computed get formattedSalesPrice() {
        if (this.currency === CURRENCY_EURO) {
            return formatMoneyEuro(this.salesPrice);
        } else if (this.currency === CURRENCY_POUND) {
            return formatMoneyPound(this.salesPrice);
        }

        throw new Error(`Unknown currency: ${this.currency}`);
    }

    @computed get salesPriceValue() {
        var value = parseFloat(this.salesPrice);

        if (isNaN(value)) {
          return 0;
        }

        return value;
    }

    /**
     * Calculates the amount for a specific surcharge in the order. If it is a percentage it will actually
     * calculate the value after taking the percentage of the related surcharge.
     * @param code - can be string or Surcharge
     * @returns {number|*}
     */
    getAmountForSurcharge(code) {
        if (typeof code !== 'string') {
            code = code.code;
        }
        let orderSurcharge = this.orderSurcharges.find(os => os.surcharge.code === code)

        if (!orderSurcharge) {
            return 0;
        }

        // Always use an adaptedAmount if it exists
        if (orderSurcharge.adaptedAmount) {
            return orderSurcharge.adaptedAmount;
        }

        // Use the amount if possible otherwise just calculate it
        if (orderSurcharge.surcharge?.type !== SURCHARGE_TYPE_PERCENTAGE) {
            return orderSurcharge.amount;
        } else {
            let percentage = orderSurcharge.surcharge.percentage / 100;
            let related_surcharge_amount = this.getAmountForSurcharge(orderSurcharge.surcharge.relatedSurcharge.code)
            return related_surcharge_amount * percentage;
        }
    }

    /**
     * The surchargeSum is the sum of all the surcharges, if there is an adapted amount it should be used instead of the
     * original surcharge amount.
     *
     * If there is a surcharge that is a percentage, we have to lookup the current value of that surcharge and our new
     * value will be the percentage of the value of that surcharge
     *
     * @returns {number|null}
     */
    get calculatedSurchargeSum(){

        if (!this.orderSurcharges){
            return null;
        }

        let sum = 0
        this.orderSurcharges.forEach(orderSurcharge => {
            // Not included if disabled
            if (!orderSurcharge.enabled) {
                return sum += 0
            }

            return sum += this.getAmountForSurcharge(orderSurcharge.surcharge)

        })

        return sum
    }

    /**
     * The suggestedSum is the sum of all the surcharges, ignoring the adapted surcharge amount
     * @returns {number|null}
     */
    get suggestedSum(){

        if (!this.orderSurcharges){
            return null;
        }

        let sum = 0
        this.orderSurcharges.forEach(crs => {sum += crs.enabled ? crs.surcharge._amount : 0})

        return sum
    }

    updateBasePriceBasedOnTotalSalesPrice(salesPrice) {
        const baseSurcharge = this.orderSurcharges.find(orderSurcharge => orderSurcharge.surcharge.code === SURCHARGE_CODE_BASE_PRICE);
        if (baseSurcharge) {
            /*
            We should adapt the base price in sucha way that we reflect the new total price.
            We can do this by taking the sum of all fixed surcharges (not including the base price)
            and taking the sum of all percentages that do not have a fixed (adapted) price.

            Then what we do is:
            basePrice + (basePrice * percentageSum) = salesPrice - fixedAmountSum
            basePrice * (1 + percentageSum) = salesPrice - fixedAmountSum
            basePrice = (salesPrice - fixedAmountSum) / (1 + percentageSum)
            basePrice = (salesPrice - fixedAmountSum) * 100 / (100 + percentageSum)
            We try to avoid small decimals, so we convert the percentage by adding 100 above the line and converting our 1.00 to 100

             */
            let fixedAmountSum = 0;
            let percentageSum = 0;

            this.orderSurcharges.forEach(os => {
                // Don't include base price or disabled surcharges
                if (os.surcharge.code === SURCHARGE_CODE_BASE_PRICE || !os.enabled) {
                    return;
                }

                // If there is a fixed price always use that
                if (os.adaptedAmount) {
                    fixedAmountSum += os.adaptedAmount;
                    return
                }

                // Check if it is a regular surcharge, or a percentage surcharge
                if (os.surcharge.type !== SURCHARGE_TYPE_PERCENTAGE) {
                    fixedAmountSum += os.surcharge.amount;
                } else {
                    // We assume a surcharge can only be related to the Base Price (01)
                    percentageSum += os.surcharge.percentage;
                }

            });

            // Use Math.round to make sure we do not get 849.99 instead of 850
            baseSurcharge.setInput('adaptedAmount', Math.ceil((salesPrice - fixedAmountSum) * 100 / (100 + percentageSum)));
        }
    }

    @computed get statusColorOverview() {
        switch (this.status) {
            case OrderStatus.ACCEPTED:
                return 'var(--blue-300)'
            case OrderStatus.PLANNED:
                return 'var(--gray-300)'
            case OrderStatus.IN_PROGRESS:
                return 'var(--orange-300)'
            case OrderStatus.COMPLETED:
                return 'var(--green-300)'
            case OrderStatus.CANCELED:
                return 'var(--red-300)'

            default:
                return 'var(--gray-100)';
        }
    }

    @computed get statusColorSemantic() {
        switch (this.status) {
            case OrderStatus.ACCEPTED:
                return 'blue'
            case OrderStatus.PLANNED:
                return 'grey'
            case OrderStatus.IN_PROGRESS:
                return 'orange'
            case OrderStatus.COMPLETED:
                return 'green'
            case OrderStatus.CANCELED:
                return 'red'
            default:
                return 'grey';
        }
    }

    @computed
    get getInvoice() {
        if (this.invoicedIn?.models.at(0)?.invoice) {
            return this.invoicedIn.models.at(0).invoice;
        }

        return null;
    }

    @computed
    get getCustomerInvoices() {
        return this.invoicedIn?.models
            // only invoices targeted for same customer
            .filter(invoicedIn => invoicedIn.invoice?.customer?.id === this.customer?.id)
            .map(invoicedIn => invoicedIn.invoice)
    }

    @computed
    get checkInvoiceStatusSent() {
        if (this.invoicedIn?.models.at(0)?.invoice) {
            return this.invoicedIn?.models.at(0)?.invoice.status === 'sent' || this.invoicedIn?.models.at(0)?.invoice.status === 'paid'
        }

        return false;
    }

    @computed
    get activeSubcontract() {
        const activeSubcontracts = this.subcontracts.filter(subcontract =>
            [OrderSubcontractStatus.DRAFT, OrderSubcontractStatus.SENT].includes(subcontract.status));

        if (activeSubcontracts.length === 0) {
            return null;
        }

        return activeSubcontracts[0];
    }

    @computed
    get hasDraftInvoice() {
        const invoiceDrafted = this.invoicedIn.models.find(obj => {
            return obj.invoice.status === 'drafted';
        });

        if (invoiceDrafted === undefined) {
            return null;
        }

        return invoiceDrafted;
    }

    isSameCurrency = (el, index, arr) => {
        if (index === 0) {
            return true;
        } else {
            return (el.invoice.currency === arr[index - 1].invoice.currency);
        }
    }

    @computed
    get invoicedFormattedTotalPrice() {
        let sameCurrency = this.invoicedIn.models.every(this.isSameCurrency);

        if (!sameCurrency) {
            return false
        }
        const sum = sumBy(this.invoicedIn.models, 'total');

        if (this.currency === CURRENCY_EURO) {
            return formatMoneyEuro(sum);
        } else if (this.currency === CURRENCY_POUND) {
            return formatMoneyPound(sum);
        }

        return null;
    }

    @action
    cancel() {
        if (this?.orderNumber === '') {
            console.error('order number is null')
        } else {
            const currentStatus = this.status;
            this.status = OrderStatus.CANCELED;

            this.save({
                fields: ['status']
            })
            .then(showSaveNotification)
            .catch((err) => {
                this.status = currentStatus;
                showErrorNotifications(err.response.data.errors);
            });
        }
    }

    @action
    reactivate() {
        if (this?.orderNumber === '') {
            console.error('order number is null')
        } else {
            const currentStatus = this.status;
            this.status = OrderStatus.NEW;

            this.save({
                fields: ['status']
            })
            .then(showSaveNotification)
            .catch((err) => {
                this.status = currentStatus;
                showErrorNotifications(err.response.data.errors);
            });
        }
    }

    checkVatRules() {
        return this.wrapPendingRequestCount(
            this.api.get(this.url + 'check_vat/')
        );
    }

    sendLimitReachedEmail = async (creditLimitAmount, creditUsed, calculatedCreditUsed) => {
        this.api.post(`${this.url}send_limit_reached_email/`, { credit_limit: creditLimitAmount, credit_used: creditUsed, calculated_credit_used: calculatedCreditUsed })
    }

    async getIdByOrderNumber(orderNumber) {
        const simpleOrderStore = new OrderStore({
            params: {
                '.order_number': orderNumber,
            },
        });

        await simpleOrderStore.fetch();
        if (simpleOrderStore.models.length > 0) {
            return simpleOrderStore.models[0].id;
        } else {
            return null
        }
    }

    async _getRecipientsOfType(type) {
        if (type === TYPE_UPDATE) {
            return this.orderContactUpdate ? this.orderContactUpdate.map(c => c.emailAddress) : [];
        }
        if (type === TYPE_CUSTOMS) {
            return this.orderContactCustoms ? this.orderContactCustoms.map(c => c.emailAddress) : [];
        }

        return this.customer ? this.customer.getRecipients([type]) : [];
    }

    async getRecipients(typePriorityList) {
        let result = [];
        let i = 0

        while (i < typePriorityList.length) {
            var receipients = await this._getRecipientsOfType(typePriorityList[i]);
            result.push(...receipients);
            i++;
        }

        return result;
    }

    getActivities = (tripType) => {
        let result = this?.trips?.models;
        if (tripType){
            result = result.filter((trip) => trip.type === tripType)
        }
        return result.reduce((acc, currVal) => acc.concat(currVal?.activities),[])

    }

    getBookings = () => {
        let bookingsList = this.getActivities().reduce((acc, currVal) => currVal.bookings && acc.concat(currVal?.bookings),[])
        return uniqBy(bookingsList, booking => booking?.id);
    }

    getTrucksLicensePlate() {
        try {
            let arrTruckLicensePlate = [];
            uniqBy(this.trips.models, trip => trip.truck.licensePlate).map(trip => {
                let truck = trip.truck.licensePlate;
                trip.m2mActivities.map((relActivity) => {
                    if (truck !== '' && ['load', 'unload'].includes(relActivity.activity.type)) {
                        if (!arrTruckLicensePlate.includes(relActivity.activity.type + ': ' + truck)) {
                            arrTruckLicensePlate.push(relActivity.activity.type + ': ' + truck)
                        }
                    }
                    return ''
                })
                return ''
            })
            return arrTruckLicensePlate;
        } catch (error) {
            return []
        }
    }

    getTrailerInfo() {
        try {
            return this.trips.models
                .filter(trip => trip.trailer.id > 0)
                .map(trip => trip.trailer)
                // make sure the values returned are unique
                .filter((trailer, index, self) =>
                    index === self.findIndex(t => t.licensePlate === trailer.licensePlate)
                );
        } catch (error) {
            return [];
        }
    }


    getGeneralDocuments() {
        return this.documents.filter(doc => doc.type !== 'proof_of_delivery' && doc.type !== 'order_invoicing')
    }

    getPodDocuments() {
        return this.documents.filter(doc => doc.type === 'proof_of_delivery')
    }

    getInvoicingDocuments() {
        return this.documents.filter(doc => doc.type === 'order_invoicing')
    }

    toBackend(...args) {
        const data = super.toBackend(...args);

        return omit(data, 'secret_hash');
    }

    addJob = () => {
        let nextBusinessDay = now().startOf('day').plus({ days: 1 });

        // Skip weekends.
        if (nextBusinessDay.toFormat('E') === '6') {
            nextBusinessDay = nextBusinessDay.plus({ days: 2 })
        } else if (nextBusinessDay.toFormat('E') === '7') {
            nextBusinessDay = nextBusinessDay.plus({ days: 1 })
        }

        const defaultProps = {
            orderedArrivalDatetimeFrom: nextBusinessDay,
            orderedArrivalDatetimeUntil: nextBusinessDay,
        };

        return this.jobs.add({ type: null, ...defaultProps });
    }

    deleteJob = (job) => {
        this.jobs.remove(job);
    }
}

export class OrderStore extends Store {
    Model = Order;
    static backendResourceName = 'order';
}
