import { observable, action } from 'mobx';
import { Model } from 'store/Base';
import { get, omit } from 'lodash';
export class Location extends Model {
    static backendResourceName = 'location';
    static omitFields = ['confidence'];

    // Hack so LocationEditFreeForm reparses the given location.
    @observable _key = null;
    @observable id = null;
    @observable address = '';
    @observable street = '';
    @observable houseNumber = '';
    @observable zipCode = '';
    @observable city = '';
    @observable country = 'NL';
    @observable geoStatus = '';
    @observable preciseMatch = true;
    @observable timezone = 'Europe/Amsterdam';
    @observable point = {
        lng: null,
        lat: null,
    }
    @observable code = '';
    @observable confidence = undefined;

    setInput(name, value) {
        this.confidence = undefined;
        super.setInput(name, value);
    }


    toBackend(options) {
        const data = super.toBackend(options);
        return omit(data, ['geo_status', 'precise_match']);
    }

    toGPSCoordinates() {
        if (this.point == null || this.point.lat == null || this.point.lng == null) {
            return '';
        }
        return `${this.point.lat}°N, ${this.point.lng}°E`;
    }

    toString() {
        return `${this.country}, ${this.zipCode}, ${this.city}`;
    }

    @action
    fetchCountries() {
        return this.api.get('/location/country/').then(res => {
            return res.data;
        });
    }

    // {geocode-copy-pasta}
    @action
    geocode() {
        this.__pendingRequestCount += 1;
        const address = this.address;
        if (!address) {
            return Promise.resolve();
        }
        this.geoStatus = 'loading';
        return this.api
            .post(
                '/location/geocode/',
                { address: address + ' Europe' },
                {
                    skipRequestError: true,
                }
            )
            .then(response => {
                if (response.data.address === 'Europe') {
                    this.geoStatus = 'notFound';
                } else {
                    this.fromBackend(response);
                    this.geoStatus = 'success';
                    this.__pendingRequestCount -= 1;

                    return response
                }
            })
            .catch(err => {
                const errorCode = get(err, 'response.data.code');
                if (errorCode) {
                    this.geoStatus = errorCode;
                }
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }

    // {geocode-save-model without calling geocode endpoint}
    @action
    geocodeSave() {
        this.__pendingRequestCount += 1;

        this.geoStatus = 'loading';
        return this.api
            .post(
                '/location/geocode_detail_save/',
                {
                    city: this.city,
                    street: this.street,
                    house_number: this.houseNumber,
                    zip_code: this.zipCode,
                },
                {
                    skipRequestError: true,
                }
            )
            .then(response => {
                return response
            });
        }
}

export class LocationWithoutPoint extends Location {
    toBackend() {
        return omit(super.toBackend(), 'point');
    }
}
